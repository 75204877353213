<template>
  <div></div>
</template>

<script>
import { clearLocalStorage } from "../../../helper/localStorage.js";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("auth", ["loginAsIntroducer"])
  },

  created() {
    clearLocalStorage();
    const config = { headers: { Authorization: "Bearer " + this.$route.query.token } };
    const payload = { introducerId: this.$route.query.introducerId, config: config };

    this.loginAsIntroducer(payload)
      .then(result => {
        this.$router.push({ name: "dashboard" });
      })
      .catch(ex => {
        this.$router.push({ name: "page-error-419" });
      });
  }
};
</script>
